import React, { useState } from 'react'

// Backend
import { API, Auth, graphqlOperation } from 'aws-amplify'
import { listReports } from '../graphql/queries'

import wishWellLogo from '../Components/Images/WishWell.png'

export class Links extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            latestReport: {}
        }

        this.loadPage = this.loadPage.bind(this)
    }

    componentDidMount() {
        this.loadPage()
    }

    async loadPage () {
        try {
            var nextToken = null
            var count = 0
            var filteredList = null
            var REPORTS = []
            while (nextToken || count === 0) {
                count = 1
                filteredList = await API.graphql(
                graphqlOperation(listReports, {
                  limit: 900,
                  nextToken:nextToken}))
                nextToken = filteredList.data.listReports.nextToken
                REPORTS = REPORTS.concat(filteredList.data.listReports.items)
            }
            REPORTS.sort(function(a, b) {
              var textA = a.createdAt;
              var textB = b.createdAt;
              return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
            });

            const user_info = await Auth.currentUserInfo()
            const username = user_info.username || 'none'

            const oneYearAgo = new Date();
            oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
            const oneYearAgoIsoString = oneYearAgo.toISOString().substr(0, 10);

            const threeMonthsAgo = new Date();
            threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
            const threeMonthsAgoIsoString = threeMonthsAgo.toISOString().substr(0, 10);

            const reportOneYearAgo = REPORTS.find((report) => report.createdAt.substr(0, 10) === oneYearAgoIsoString);
            const reportThreeMonthsAgo = REPORTS.find((report) => report.createdAt.substr(0, 10) === threeMonthsAgoIsoString);

            var ttmRevenue = null
            var ARRProjectedLTM = null
            if (reportOneYearAgo) {
                if (reportOneYearAgo.feesGenerated) {
                    ttmRevenue = (REPORTS[0].feesGenerated - reportOneYearAgo.feesGenerated)
                    console.log("feesGenerated")
                }
                else {
                    ttmRevenue = (REPORTS[0].donations - reportOneYearAgo.donations) * .05
                    console.log("donationsProxy")
                }
            } else {
                ttmRevenue = "Not enough data"
            }
            if (reportThreeMonthsAgo) {
                if (reportThreeMonthsAgo.feesGenerated) {
                    ARRProjectedLTM = (REPORTS[0].feesGenerated - reportThreeMonthsAgo.feesGenerated) * 4
                }
                else {
                    ARRProjectedLTM = (REPORTS[0].donations - reportThreeMonthsAgo.donations) * 4 * .05
                }
            } else {
                ARRProjectedLTM = "Not enough data"
            }


            this.setState({
                latestReport: REPORTS[0],
                ttmRevenue: ttmRevenue,
                ARRProjectedLTM: ARRProjectedLTM,
                user: username
            });
          } catch (error) {
            console.error(error);
          }
    }
    render() {
        return (
            <div>
                <nav className='navbar'>
                    <div style={{ position: "fixed", top: 0, left: 0, right: 0, zIndex: 999 }}>
                        <div style={{ textAlign: "center", backgroundColor: '#0A003A', padding: "20px", height: "40px" }}>
                            <div><img src={wishWellLogo} alt={wishWellLogo} style={{ float: "left", height: "40px" }}></img></div>
                            <button style={{ backgroundColor: '#fff', borderRadius: '2px', padding: '10px 20px', float: 'right' }} onClick={() => window.open('https://www.onegift.ai/resources', '_blank')}>Resources</button>
                        </div>
                    </div>
                </nav>
                <div style={{ textAlign: 'center', paddingTop: '100px' }}>
                    <h2>OneGift SOPs</h2>
                    <ul style={{ textAlign: 'left', width: '60%', display: 'inline-block' }}>
                        <li style={{ fontWeight: 'bold' }}>Corporate (Asana: <a href="http://wishwell.ai/brain" target="_blank" rel="noreferrer">wishwell.ai/brain</a>)</li>
                        <ul>
                            <li><strong>Mission:</strong> increase donations (<a href="https://docs.google.com/spreadsheets/d/1Dzdb8_tEqDWg1KZb-YvQ5bOc-gcyJ2Nm3AmHPc03GpA/edit#gid=2132848528" target='_blank' rel="noreferrer">Goals and org chart</a>)</li>
                            <li style={{ fontWeight: 'bold' }}>Values</li>
                            <ul>
                                <li><strong>Mission-driven:</strong> we're on a mission to empower generosity and connection with technology, and we exude a passion and commitment to driving donations towards the truly important causes that our customers support.</li>
                                <li><strong>Generous:</strong> to empower generosity, we must be generous ourselves. Generous with our time and resources to our team, our customers, our communities, and beyond.</li>
                                <li><strong>Joyful:</strong> life is short. We must spend every moment we can being healthy, happy, and joyful. We are grateful to be working on the OneGift mission, supporting generous leaders throughout the global giving community.</li>
                            </ul>
                            <li><strong>Charitable Giving and Customer Incentives:</strong> 10% of all profits will be split between all customers (or charity partners) on a regular basis</li>
                            <li style={{ fontWeight: 'bold' }}>Incentives</li>
                            <ul>
                                <li>Profit Sharing: 10% of all profits will be split between all employees on a regular basis (no caps, no clawbacks)</li>
                                <li>Referrals / Commissions: $.50 for every newly active donor</li>
                            </ul>
                            <li><strong>Metrics:</strong> (Details - Mixpanel: <a href="https://wishwell.ai/metrics" target='_blank' rel="noreferrer">wishwell.ai/metrics</a>)</li>
                            <ul>
                                <li>Total Organizations: {this.state.latestReport.organizations ? this.state.latestReport.organizations.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 }) : null}</li>
                                <li>Active Organizations: {this.state.latestReport.activeOrganizations ? this.state.latestReport.activeOrganizations.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 }) : null}</li>
                                <li>Org Churn: {this.state.latestReport.orgChurn ? this.state.latestReport.orgChurn.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 }) : 0}</li>
                                <li>Total Users: {this.state.latestReport.users ? this.state.latestReport.users.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 }) : null}</li>
                                <li>Total Donors: {this.state.latestReport.members ? this.state.latestReport.members.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 }) : null}</li>
                                <li>Active Donors: { this.state.latestReport.activeMembers ? this.state.latestReport.activeMembers.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 }) : null}</li>
                                <li>Donor Churn: {this.state.latestReport.memberChurn ? this.state.latestReport.memberChurn.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 }) : 0}</li>
                                <li>Total Wishes: {this.state.latestReport.wishes ? (this.state.latestReport.wishes + 868786).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 }) : null}</li>
                                {this.state.user === 'ryan@onegift.ai' && <React.Fragment><li>Total Donations: ${this.state.latestReport.donations ? (this.state.latestReport.donations + 868786).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : null}</li>
                                <li>TTM Fees: ${this.state.ttmRevenue ? this.state.ttmRevenue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : null}</li>
                                <li>Projected Annual Fees (from last 3 months): ${this.state.ARRProjectedLTM ? this.state.ARRProjectedLTM.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : null}</li></React.Fragment>}
                                {/* <li>Customer Sources: {this.state.latestReport.customerSources}</li> */}
                            </ul>
                        </ul>
                        <li style={{ fontWeight: 'bold' }}>Product (Jira/Confluence: <a href="http://wishwell.ai/dev" target="_blank" rel="noreferrer">wishwell.ai/dev</a>)</li>
                        <ul>
                            <li>Product roadmap and feature requests <a href="http://wishwell.ai/roadmap" target="_blank" rel="noreferrer">wishwell.ai/features</a></li>
                            <li>Bugs (Bugsnag) <a href="https://wishwell.ai/bugs" target='_blank' rel="noreferrer">wishwell.ai/bugs</a></li>
                        </ul>
                        <li style={{ fontWeight: 'bold' }}>Growth (<a href="https://docs.google.com/spreadsheets/d/1sqv0kYZsow9Kcn1sb0fbhcyyIhl2sQCNCOvQ-AVwOKI/edit#gid=990970575" target="_blank" rel="noreferrer">Marketing and sales tracker</a>)</li>
                        <ul>
                            <li style={{ fontWeight: 'bold' }}>Departments</li>
                            <ul>
                                <li>Attract (marketing) - create and promote content to attract people and organizations to OneGift and our products</li>
                                <li>Engage (sales) - reach out and engage potential customers and partners to educate and expand our global reach</li>
                                <li>Delight (customer success/service) - delight existing customers by ensuring a delightful experience along with meaningful donations growth</li>
                            </ul>
                            <li style={{ fontWeight: 'bold' }}>Frameworks</li>
                            <ul>
                                <li>Attract (marketing) - <a href="https://www.productmarketingalliance.com/product-marketing-framework/" target="_blank" rel="noreferrer">Product Marketing Framework</a></li>
                                <li>Engage (sales) - Founding Sales by Kazanjy</li>
                                {/* <li>Delight (customer success/service) - </li> */}
                            </ul>
                            <li style={{ fontWeight: 'bold' }}>Tools</li>
                            <ul>
                                <li>HootSuite (Social Media): <a href="http://wishwell.ai/social" target="_blank" rel="noreferrer">wishwell.ai/social</a></li>
                                <li>Apollo (Lead Generator): <a href="http://wishwell.ai/prospects" target="_blank" rel="noreferrer">wishwell.ai/prospects</a></li>
                                <li>Hubspot (CRM): <a href="http://wishwell.ai/growth" target="_blank" rel="noreferrer">wishwell.ai/growth</a></li>
                            </ul>
                            <li style={{ fontWeight: 'bold' }}>Pipeline</li>
                            <ul>
                                <li>Leads (Attract): inbound opportunities not yet qualified</li>
                                <li>Prospects/MQL's (Engage): prospects identified as ideal potential customers or qualified inbound leads</li>
                                <li>SQL's (Engage): responsive prospects/MQL's that have not committed to a meeting yet</li>
                                <li>Opportunities (Delight): qualified and interested buyers that need to be actively engaged in a deal pipeline</li>
                                <ul>
                                    <li>Demo scheduled</li>
                                    <li>Decision maker bought in</li>
                                    <li>Registered</li>
                                    <li>Activated - contact becomes a customer when they have enabled WishWell and are actively generating donations from the product</li>
                                </ul>
                            </ul>
                            <li><strong>Customer Advocate Referral Links:</strong> wishwell.ai/advocates/ADVOCATENAME</li>
                            <li><strong>Partner Referral Links:</strong> wishwell.ai/partners/PARTNERNAME</li>
                        </ul>
                        <li style={{ fontWeight: 'bold' }}>Resources (<a href="http://onegift.ai/resources" target="_blank" rel="noreferrer">onegift.ai/resources</a>)</li>
                        <ul>
                            <li>Why Wishwell (Organizations) <a href="http://wishwell.ai/whywishwell" target="_blank" rel="noreferrer">wishwell.ai/whywishwell</a></li>
                            <li>Why Wishwell (Donors) <a href="http://wishwell.ai/why" target="_blank" rel="noreferrer">wishwell.ai/why</a></li>
                            <li>Deck <a href="http://onegift.ai/deck" target="_blank" rel="noreferrer">onegift.ai/deck</a></li>
                            <li>1-pager <a href="http://onegift.ai/1pager" target="_blank" rel="noreferrer">onegift.ai/1pager</a></li>
                            <li>How it works <a href="https://wishwell.ai/howitworks" target="_blank" rel="noreferrer">wishwell.ai/howitworks</a></li>
                            <li>Whitepaper <a href="https://wishwell.ai/whitepaper" target="_blank" rel="noreferrer">wishwell.ai/whitepaper</a></li>
                            <li>Customer Case Study (PAP Corp) <a href="http://wishwell.ai/papcase" target="_blank" rel="noreferrer">wishwell.ai/papcase</a></li>
                            <li>Watch Demo <a href="http://wishwell.ai/demo" target="_blank" rel="noreferrer">wishwell.ai/demo</a></li>
                            <li>Schedule Demo <a href="https://wishwell.ai/scheduledemo" target="_blank" rel="noreferrer">wishwell.ai/scheduledemo</a></li>
                            <li>WishWell Signup <a href="https://wishwell.ai/signup" target="_blank" rel="noreferrer">wishwell.ai/signup</a></li>
                            <li>Kickoff Meeting <a href="https://wishwell.ai/kickoff" target="_blank" rel="noreferrer">wishwell.ai/kickoff</a></li>
                            <li>How to Get Started <a href="https://wishwell.ai/getstarted" target="_blank" rel="noreferrer">wishwell.ai/getstarted</a></li>                            
                            <li>Admin Setup Tutorial <a href="http://wishwell.ai/setuptutorial" target="_blank" rel="noreferrer">wishwell.ai/setuptutorial</a></li>
                            <li>Donor Make Wishes Tutorial <a href="http://wishwell.ai/wishestutorial" target="_blank" rel="noreferrer">wishwell.ai/wishestutorial</a></li>
                            <li>YouTube Page <a href="https://www.youtube.com/@onegift" target='_blank' rel="noreferrer">wishwell.ai/videos</a></li>
                            <li>Record Screen (for customers) <a href="http://wishwell.ai/recordscreen" target="_blank" rel="noreferrer">wishwell.ai/recordscreen</a></li>
                            <li>Remote Support (admin login) <a href="http://wishwell.ai/remote" target="_blank" rel="noreferrer">wishwell.ai/remote</a></li>
                            <li>Remote Support (for customers) <a href="http://wishwell.ai/remotesupport" target="_blank" rel="noreferrer">wishwell.ai/remotesupport</a></li>
                        </ul>
                    </ul>
                </div>
            </div>
        )
    }
}

export default Links