import React from 'react'
import { API, graphqlOperation, Auth } from 'aws-amplify'
import awsconfig from '../aws-exports'
// import Main from './Main'
import { createMember, updateMember } from '../graphql/mutations'
import { userByUserName, membersByOrganizationId, getOrganization } from '../graphql/queries'
import { v4 as uuid } from 'uuid'

// UI
import { TextInput, Textarea, Button, Spinner, SavedIcon, Checkbox, toaster } from 'evergreen-ui'
// import '../Styles/tables.css'

// Bug tracking
import Bugsnag from '@bugsnag/js'

// Analytics
import mixpanel from 'mixpanel-browser';
import { VerticalAlign } from 'docx'

class MemberDetails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            member: {
                id: this.props.id === undefined ? null : this.props.id,
                pnumber: this.props.pnumber === undefined ? null : this.props.pnumber,
                mnumber: this.props.mnumber === undefined ? null : this.props.mnumber,
                ptype: this.props.ptype === undefined ? null : this.props.ptype,
                pactive: this.props.pactive === undefined ? true : this.props.pactive,
                eforms: this.props.eforms === undefined ? true : this.props.eforms,
                pforms: this.props.pforms === undefined ? true : this.props.pforms,
                ecards: this.props.ecards === undefined ? true : this.props.ecards,
                pcards: this.props.pcards === undefined ? true : this.props.pcards,
                birthdayBooster: this.props.birthdayBooster === undefined ? false : this.props.birthdayBooster,
                fname: this.props.fname === undefined ? '' : this.props.fname,
                lname: this.props.lname === undefined ? '' : this.props.lname,
                email: this.props.email === undefined ? '' : this.props.email,
                phone: this.props.phone === undefined ? '' : this.props.phone,
                address1: this.props.address === undefined ? '' : this.props.address,
                address2: this.props.addressRemote === undefined ? '' : this.props.addressRemote,
                DOB: this.props.DOB === undefined ? '' : this.props.DOB,
                anumber: this.props.anumber === undefined ? null : this.props.anumber,
                apayment: this.props.apayment === undefined ? '' : this.props.apayment,
                notes: this.props.notes === undefined ? '' : this.props.notes,
                organizationId: this.props.organizationId === undefined ? '' : this.props.organizationId,
                searchField: this.props.searchField === undefined ? '' : this.props.searchField
            },
            organization: {
                id: '',
                name: '',
                parentOrgId: '',
                active: true,
                independent: false,
            }
          }

        this.componentDidMount = this.componentDidMount.bind(this)
        this.loadPage = this.loadPage.bind(this)
        this.componentDidUpdate = this.componentDidUpdate.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.getFieldValues = this.getFieldValues.bind(this)
        this.saveMember = this.saveMember.bind(this)
    }

    componentDidMount () {
        this.loadPage()
    }

    loadPage = async () => {
        try {
            // Check for existing setting record
            const user_info = await Auth.currentUserInfo()
            const username = user_info.username
            const user = await API.graphql(graphqlOperation(userByUserName, { userName: username }))
            const orgId = user.data.userByUserName.items[0].organizationId
            const organization_return = await API.graphql(graphqlOperation(getOrganization, { id: orgId }))
            this.setState({
                organization: organization_return.data.getOrganization
            })
        } catch (error) {
            Bugsnag.notify(error);
            console.log('Error fetching settings: ' + error)
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
          this.setState({
            member: {
                id: this.props.id === undefined ? null : this.props.id,
                pnumber: this.props.pnumber === undefined ? 0 : this.props.pnumber,
                mnumber: this.props.mnumber === undefined ? 0 : this.props.mnumber,
                ptype: this.props.ptype === undefined ? 0 : this.props.ptype,
                pactive: this.props.pactive === undefined ? true : this.props.pactive,
                eforms: this.props.eforms === undefined ? true : this.props.eforms,
                pforms: this.props.pforms === undefined ? true : this.props.pforms,
                ecards: this.props.ecards === undefined ? true : this.props.ecards,
                pcards: this.props.pcards === undefined ? true : this.props.pcards,
                birthdayBooster: this.props.birthdayBooster === undefined ? false : this.props.birthdayBooster,
                fname: this.props.fname === undefined ? '' : this.props.fname,
                lname: this.props.lname === undefined ? '' : this.props.lname,
                email: this.props.email === undefined ? '' : this.props.email,
                phone: this.props.phone === undefined ? '' : this.props.phone,
                address1: this.props.address === undefined ? '' : this.props.address,
                address2: this.props.addressRemote === undefined ? '' : this.props.addressRemote,
                DOB: this.props.DOB === undefined ? '' : this.props.DOB,
                anumber: this.props.anumber === undefined ? null : this.props.anumber,
                apayment: this.props.apayment === undefined ? '' : this.props.apayment,
                notes: this.props.notes === undefined ? '' : this.props.notes,
                organizationId: this.props.organizationId === undefined ? '' : this.props.organizationId,
                searchField: this.props.searchField === undefined ? '' : this.props.searchField
            }
          });
        }
      }

    handleChange(event) {
        const target = event.target
        const updatedMember = this.state.member
        if (target.type === "checkbox") {
            const checked = target.checked
            updatedMember[event.target.id] = checked
            this.setState({
                member: updatedMember
            })
        }
        else {
            const field = target.name
            const value = target.value
            updatedMember[field] = value
            this.setState({
                member: updatedMember
            })
        }
    }

    getFieldValues () {
        this.setState({
            member: {
                id: this.props.id === undefined ? null : this.props.id,
                pnumber: parseInt(document.getElementById("pnumber").value),
                mnumber: document.getElementById("mnumber") ? parseInt(document.getElementById("mnumber").value === '' ? null : document.getElementById("mnumber").value): null,
                ptype: document.getElementById("ptype") ? parseInt(document.getElementById("ptype").value) : null,
                pactive: document.getElementById("pactive").checked,
                eforms: document.getElementById("eforms") ? document.getElementById("eforms").checked : true,
                pforms: document.getElementById("pforms") ? document.getElementById("pforms").checked: true,
                ecards: document.getElementById("ecards") ? document.getElementById("ecards").checked : true,
                pcards: document.getElementById("pcards") ? document.getElementById("pcards").checked : true,
                birthdayBooster: document.getElementById("birthdayBooster") ? document.getElementById("birthdayBooster").checked : false,
                fname: document.getElementById("fname").value,
                lname: document.getElementById("lname").value,
                email: document.getElementById("email").value,
                phone: document.getElementById("phone").value,
                address1: document.getElementById("address1").value,
                address2: document.getElementById("address2").value,
                DOB: document.getElementById("DOB").value,
                anumber: document.getElementById("anumber") ? parseInt(document.getElementById("anumber").value) : null,
                apayment: document.getElementById("apayment") ? document.getElementById("apayment").value : '',
                notes: document.getElementById("notes").value,
            }
        })
    }

    saveMember = async () => {

        this.setState({ savingMember: true })

        try {
            this.getFieldValues()
            if (
                !this.state.member.pnumber || 
                this.state.member.fname === '' || 
                this.state.member.lname === '' || 
                (this.state.member.address1 === '' && this.state.member.email === '') ||
                this.state.member.DOB === ''
                ) {
                    if (!window.confirm('ID Number, first name, last name, DOB/Anniversary, and either an email or primary address are recommended before saving. Are you sure you want to proceed?')) {
                        return
                    }
                }

            var nextToken = null;
            var count = 0
            var filteredList
            var MEMBERS = []
            const user_info = await Auth.currentUserInfo()
            const username = user_info.username
            const user = await API.graphql(graphqlOperation(userByUserName, { userName: username }))
            const orgId = user.data.userByUserName.items[0].organizationId
            while (nextToken || count === 0) {
                count = 1
                filteredList = await API.graphql(
                graphqlOperation(membersByOrganizationId, {
                    organizationId: orgId,
                    limit: 900,
                    nextToken:nextToken
                }))
                nextToken = filteredList.data.membersByOrganizationId.nextToken
                MEMBERS = MEMBERS.concat(filteredList.data.membersByOrganizationId.items)
            }

            // If member already exists, update it
            if (MEMBERS.some(e => e.id === this.state.member.id)) {
                // Check to make sure ID number is not already in use
                if (MEMBERS.some(e => e.pnumber === this.state.member.pnumber && e.id !== this.state.member.id)) {
                    // Get next available ID number
                    let nextId = 1;
                    while (MEMBERS.some(e => e.pnumber === nextId)) {
                        nextId++;
                    }

                    toaster.warning('ID number already in use! The next available ID is: ' + nextId.toString(), {duration: 5,})
                    return
                }
                else {
                    const updatedMember = this.state.member
                    updatedMember.searchField = this.state.member.pnumber + " " + this.state.member.fname.toLowerCase() + " " + this.state.member.lname.toLowerCase() + " " + this.state.member.pactive
                    try {
                        await API.graphql(graphqlOperation(updateMember, {input: updatedMember}))

                        // Tracking
                        toaster.success('Member updated!', {duration: 5,})
                        // this.props.reloadMain("MemberDetails");
                        } 
                    catch (error) {
                        Bugsnag.notify(error);
                        toaster.danger('Operation failed: ' + error, {duration: 5,})
                    }
                }
            }
            // If member does not exist, create it
            else {
                // Check to make sure PAP number is not already in use
                if (MEMBERS.some(e => e.pnumber === this.state.member.pnumber)) {
                    toaster.warning('PAP number already in use!', {duration: 5,})
                }
                else {
                    try {
                        //Generate new UUID for new member
                        var updatedMember = this.state.member
                        updatedMember.id = uuid()
                        updatedMember.organizationId = orgId
                        updatedMember.searchField = this.state.member.pnumber + " " + this.state.member.fname.toLowerCase() + " " + this.state.member.lname.toLowerCase() + " " + this.state.member.pactive
                        await API.graphql(graphqlOperation(createMember, {input: updatedMember}))

                        // Tracking
                        mixpanel.track('Member record created');
                        toaster.success('Member saved!', {duration: 5,})
                        this.props.reloadMain();
                    } catch (error) {
                        Bugsnag.notify(error);
                        toaster.danger('Operation failed: ' + error, {duration: 5,})
                    }
                }
            }
            this.setState({ savingMember: false })
        } catch (error) {
            this.setState({ savingMember: false })
            Bugsnag.notify(error);
            toaster.danger('Operation failed: ' + error, {duration: 5,})
        }
    }

    render() {
        
        return (
            <div>
            <div style={{ textAlign: "right", display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
                <br></br>
                <form>
                    <table className="responsive-table" style={{borderSpacing: '10px'}}><tbody>
                        <tr><td colSpan={2}>
                            <tr>
                                <td style={{ width: '250px' }}><label htmlFor="fname">First Name* </label></td>
                                <td><TextInput type="text" id="fname" name="fname" value={this.state.member.fname} onChange={this.handleChange}></TextInput></td>
                                <td><label htmlFor="pnumber">ID Number* </label></td>
                                <td><TextInput type="number" id="pnumber" name="pnumber" value={this.state.member.pnumber} onChange={this.handleChange}></TextInput></td>
                            </tr>
                            <tr>
                                <td><label htmlFor="lname">Last Name* </label></td>
                                <td><TextInput type="text" id="lname" name="lname" value={this.state.member.lname} onChange={this.handleChange}></TextInput></td>
                                <td><label htmlFor="DOB">Birthday* </label></td>
                                <td><TextInput type="date" id="DOB" name="DOB" value={this.state.member.DOB} onChange={this.handleChange}></TextInput></td>
                            </tr>
                            <tr>
                                <td><label htmlFor="email">E-mail </label></td>
                                <td><TextInput type="email" id="email" name="email" value={this.state.member.email} onChange={this.handleChange}></TextInput></td>
                                <td><label htmlFor="address1">Address </label></td>
                                <td><TextInput placeholder="123 Anywhere St, Boynton Beach, FL 33437" type="text" id="address1" name="address1" value={this.state.member.address1} onChange={this.handleChange}></TextInput></td>
                            </tr>
                            <tr>
                                <td><label htmlFor="phone">Phone </label></td>
                                <td><TextInput type="text" id="phone" name="phone" value={this.state.member.phone} onChange={this.handleChange}></TextInput></td>
                                <td><label htmlFor="address2">Address 2 </label></td>
                                <td><TextInput type="text" id="address2" name="address2" value={this.state.member.address2} onChange={this.handleChange}></TextInput></td>  
                            </tr>
                            {/* TODO: remove hard-coded pap custom field filter */}
                            {this.state.organization.parentOrgId === '859d0bb4-21c4-4c0d-9804-126093d78552' && <div>
                            <tr>
                                <td><label htmlFor="ptype">PAP Type (1-5)* </label></td>
                                <td><TextInput type="number" id="ptype" name="ptype" value={this.state.member.ptype} onChange={this.handleChange}></TextInput></td>
                                <td><label htmlFor="mnumber">Member Number </label></td>
                                <td><TextInput type="number" id="mnumber" name="mnumber" value={this.state.member.mnumber} onChange={this.handleChange}></TextInput></td>
                            </tr>
                            <tr>
                                <td><label htmlFor="anumber">Angel Number </label></td>
                                <td><TextInput type="number" id="anumber" name="anumber" value={this.state.member.anumber} onChange={this.handleChange}></TextInput></td>
                                <td><label htmlFor="apayment">Last Angel Payment </label></td>
                                <td><TextInput disabled={this.state.member.anumber === 0 ? true : false} type="date" id="apayment" name="apayment" value={this.state.member.apayment} onChange={this.handleChange}></TextInput></td>    
                            </tr></div>}
                            <tr>
                                <td><label htmlFor="notes">Notes </label></td>
                                <td><Textarea type="text" id="notes" name="notes" value={this.state.member.notes} onChange={this.handleChange}></Textarea></td>
                            </tr>
                        </td>
                        <td className="responsive-table-checkbox">
                            <tr>
                                <td><label htmlFor="pactive">Active?</label></td>
                                <td><Checkbox type="checkbox" id="pactive" name="pactive" checked={this.state.member.pactive} onChange={this.handleChange}></Checkbox></td>
                            </tr>
                            <tr>
                                <td><label htmlFor="birthdayBooster">Birthday Booster</label></td>
                                <td><Checkbox type="checkbox" id="birthdayBooster" name="birthdayBooster" checked={this.state.member.birthdayBooster} onChange={this.handleChange}></Checkbox></td>
                            </tr>
                            <tr>
                                <td><label htmlFor="pforms">Mail Order Forms</label></td>
                                <td><Checkbox type="checkbox" id="pforms" name="pforms" checked={this.state.member.pforms} onChange={this.handleChange}></Checkbox></td>
                            </tr>
                            <tr>
                                <td><label htmlFor="eforms">Email Order Form</label></td>
                                <td><Checkbox type="checkbox" id="eforms" name="eforms" checked={this.state.member.eforms} onChange={this.handleChange}></Checkbox></td>
                            </tr>
                            <tr>
                                <td><label htmlFor="pcards">Mail Cards</label></td>
                                <td><Checkbox type="checkbox" id="pcards" name="pcards" checked={this.state.member.pcards} onChange={this.handleChange}></Checkbox></td>
                            </tr>
                            <tr>
                                <td><label htmlFor="ecards">Email Cards</label></td>
                                <td><Checkbox type="checkbox" id="ecards" name="ecards" checked={this.state.member.ecards} onChange={this.handleChange}></Checkbox></td>
                            </tr>
                        </td>
                        </tr>
                    </tbody></table>
                </form>
                <br></br>
            </div>
            <div style={{ textAlign: "left", display: 'flex', justifyContent: 'center', marginTop: '20px', paddingBottom: '50px' }}>
                <Button iconBefore={SavedIcon} onClick={this.saveMember} appearance='primary' intent='none'>
                    {this.state.savingMember && <Spinner size={16} marginRight={8} />}
                    Save Member
                </Button>
            </div>
            </div>
        )
    }
}

export default MemberDetails