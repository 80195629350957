import React, {Component, useEffect} from 'react'
import { API, Auth, graphqlOperation } from 'aws-amplify'
import AWS from "aws-sdk";
import awsconfig from './aws-exports';

// DB
import { userByUserName, settingsByMakeAWishURL } from './graphql/queries'
import { createUser, createOrganization, createSetting } from './graphql/mutations'
import { v4 as uuid } from 'uuid'

// UI
import { Spinner, FlashIcon, HomeIcon, WalkIcon, InheritedGroupIcon, UserIcon, PeopleIcon, SymbolCrossIcon, EyeOpenIcon, DollarIcon, CogIcon, BookIcon, HandIcon, CleanIcon, IconButton, AlignJustifyIcon, HelpIcon, LearningIcon, FollowerIcon, VideoIcon, Tooltip, Button, CalendarIcon, Popover, Menu, Position, TextInputField, toaster, Pane, Dialog, Autocomplete, Label, Checkbox } from 'evergreen-ui'
import logo from './wishwellbyonegift.svg'
import './Styles/button.css'
import './Styles/signin.css'
import wishWellLogo from './Components/Images/WishWell.png'

// Bug tracking
import Bugsnag from '@bugsnag/js'

// Analytics
import mixpanel from 'mixpanel-browser';

export class CustomSignIn extends Component {
  constructor(props) {
    super(props)
    this._validAuthStates = ['signIn', 'signedOut', 'signedUp']
    this.signIn = this.signIn.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.state = { 
      signInLoading: false,
      legalStuff: false,
      counter: 0,
      counting: true,
    }
    this.componentDidMount = this.componentDidMount.bind(this);
    this.componentWillUnmount = this.componentWillUnmount.bind(this);
    this.checkURL = this.checkURL.bind(this);
    this.confirmUser = this.confirmUser.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.forgotPassword = this.forgotPassword.bind(this);
    this.signUp = this.signUp.bind(this);
    this.subscribeToHubspot = this.subscribeToHubspot.bind(this);
    this.firstSignIn = this.firstSignIn.bind(this);
    this.signIn = this.signIn.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.generatePassword = this.generatePassword.bind(this)
    this.setFirstPassword = this.setFirstPassword.bind(this)
  }

  componentDidMount() {
    mixpanel.track('WishWell.OneGift.ai Visited');
    this.checkURL()
    this.loadWishes()
    this.loadCountries()
    this.intervalId = setInterval(() => {
      this.setState(prevState => {
        while (!this.state.wishes || this.state.counter < this.state.wishes) {
          return { counter: prevState.counter + 6234 };
        }
        return ({counting: false})
      });
    }, 10);

    // Load the HubSpot CTA script when the component mounts
    const script = document.createElement('script');
    script.src = 'https://js.hscta.net/cta/current.js';
    script.async = true;
    document.body.appendChild(script);

    this.script = script; // Store the script to remove it later if needed
  }

  componentWillUnmount() {
    if (this.state.counter >= this.state.wishes){
      clearInterval(this.intervalId);
    }
    // Cleanup the hubspot cta script when the component unmounts
    if (this.script) {
      document.body.removeChild(this.script);
    }
  }

  async loadCountries() {
    const userCountry = await this.getUserCountry();
    const countryNames = [
      'Australia',
      'Austria',
      'Belgium',
      'Bulgaria',
      'Canada',
      'Croatia',
      'Cyprus',
      'Czech Republic',
      'Denmark',
      'Estonia',
      'Finland',
      'France',
      'Germany',
      'Gibraltar',
      'Greece',
      'Hong Kong',
      'Hungary',
      'Ireland',
      'Italy',
      'Japan',
      'Latvia',
      'Liechtenstein',
      'Lithuania',
      'Luxembourg',
      'Malta',
      'Mexico',
      'Netherlands',
      'New Zealand',
      'Norway',
      'Poland',
      'Portugal',
      'Romania',
      'Singapore',
      'Slovakia',
      'Slovenia',
      'Spain',
      'Sweden',
      'Switzerland',
      'Thailand',
      'United Arab Emirates',
      'United Kingdom',
      'United States'
    ];
    const userCountryIndex = countryNames.indexOf(userCountry);
    if (userCountryIndex !== -1) {
      countryNames.splice(userCountryIndex, 1);
      countryNames.unshift(userCountry);
    }
    const allCountryNames = countryNames.map(country => ({ value: country, label: country }));

    const stripeCountryInfo = [
      {
        country: 'Australia',
        code: 'AU',
        currency: 'AUD'
      },
      {
        country: 'Austria',
        code: 'AT',
        currency: 'EUR'
      },
      {
        country: 'Belgium',
        code: 'BE',
        currency: 'EUR'
      },
      {
        country: 'Bulgaria',
        code: 'BG',
        currency: 'BGN'
      },
      {
        country: 'Canada',
        code: 'CA',
        currency: 'CAD'
      },
      {
        country: 'Croatia',
        code: 'HR',
        currency: 'HRK'
      },
      {
        country: 'Cyprus',
        code: 'CY',
        currency: 'EUR'
      },
      {
        country: 'Czech Republic',
        code: 'CZ',
        currency: 'CZK'
      },
      {
        country: 'Denmark',
        code: 'DK',
        currency: 'DKK'
      },
      {
        country: 'Estonia',
        code: 'EE',
        currency: 'EUR'
      },
      {
        country: 'Finland',
        code: 'FI',
        currency: 'EUR'
      },
      {
        country: 'France',
        code: 'FR',
        currency: 'EUR'
      },
      {
        country: 'Germany',
        code: 'DE',
        currency: 'EUR'
      },
      {
        country: 'Gibraltar',
        code: 'GI',
        currency: 'GBP'
      },
      {
        country: 'Greece',
        code: 'GR',
        currency: 'EUR'
      },
      {
        country: 'Hong Kong',
        code: 'HK',
        currency: 'HKD'
      },
      {
        country: 'Hungary',
        code: 'HU',
        currency: 'HUF'
      },
      {
        country: 'Ireland',
        code: 'IE',
        currency: 'EUR'
      },
      {
        country: 'Italy',
        code: 'IT',
        currency: 'EUR'
      },
      {
        country: 'Japan',
        code: 'JP',
        currency: 'JPY'
      },
      {
        country: 'Latvia',
        code: 'LV',
        currency: 'EUR'
      },
      {
        country: 'Liechtenstein',
        code: 'LI',
        currency: 'CHF'
      },
      {
        country: 'Lithuania',
        code: 'LT',
        currency: 'EUR'
      },
      {
        country: 'Luxembourg',
        code: 'LU',
        currency: 'EUR'
      },
      {
        country: 'Malta',
        code: 'MT',
        currency: 'EUR'
      },
      {
        country: 'Mexico',
        code: 'MX',
        currency: 'MXN'
      },
      {
        country: 'Netherlands',
        code: 'NL',
        currency: 'EUR'
      },
      {
        country: 'New Zealand',
        code: 'NZ',
        currency: 'NZD'
      },
      {
        country: 'Norway',
        code: 'NO',
        currency: 'NOK'
      },
      {
        country: 'Poland',
        code: 'PL',
        currency: 'PLN'
      },
      {
        country: 'Portugal',
        code: 'PT',
        currency: 'EUR'
      },
      {
        country: 'Romania',
        code: 'RO',
        currency: 'RON'
      },
      {
        country: 'Singapore',
        code: 'SG',
        currency: 'SGD'
      },
      {
        country: 'Slovakia',
        code: 'SK',
        currency: 'EUR'
      },
      {
        country: 'Slovenia',
        code: 'SI',
        currency: 'EUR'
      },
      {
        country: 'Spain',
        code: 'ES',
        currency: 'EUR'
      },
      {
        country: 'Sweden',
        code: 'SE',
        currency: 'SEK'
      },
      {
        country: 'Switzerland',
        code: 'CH',
        currency: 'CHF'
      },
      {
        country: 'Thailand',
        code: 'TH',
        currency: 'THB'
      },
      {
        country: 'United Arab Emirates',
        code: 'AE',
        currency: 'AED'
      },
      {
        country: 'United Kingdom',
        code: 'GB',
        currency: 'GBP'
      },
      {
        country: 'United States',
        code: 'US',
        currency: 'USD'
      }
    ];

    this.setState({ 
      countries: allCountryNames,
      stripeCountryInfo: stripeCountryInfo,
      country: { value: 'United States', label: 'United States' },
    });
  }

  getUserCountry() {
    return fetch('https://ipapi.co/json/')
      .then(response => response.json())
      .then(data => {
        const country = data.country_name;
        return country;
      })
      .catch(error => {
        console.error(error);
      });
  }

  async loadWishes() {
    try {
      // Retrieve active members
      const apiName = 'publicorderform';
      var path = '/publicorderform/getwishescount';
      var myInit = { // OPTIONAL
        body: {},
        headers: {}, // OPTIONAL
    };

      const orders = await API.post(apiName, path, myInit);
      const wishes = orders.body +  868786
      this.setState({wishes: wishes})
    } catch (error) {
        Bugsnag.notify(error);
        console.log(error)
    }
  }

  checkURL () {
    const searchParams = new URLSearchParams(window.location.search);
    const URLFunction = searchParams.get('function');
    const URLStatus = searchParams.get('status');
    const URLSource = searchParams.get('source');
    const URLReferrer = searchParams.get('referrer');
    const emailVerified = searchParams.get('emailVerified');

    if (emailVerified && emailVerified === "true") {
      toaster.success('Email verified successfully! WishWell will now come from your own email address that you set in your Settings page.');
    }
    else if (emailVerified && emailVerified === "false") {
      toaster.warning('Error verificatin email. Please try again or contact support.');
    }
    if (URLFunction === 'signup' && !(URLStatus === 'done')) {
      this.confirmUser()
    }
    if (URLFunction === 'reset' && !(URLStatus === 'done')) {
      mixpanel.track('User clicked password reset link in email');
      this.setState({passwordResetModal: true})
    }
    if (URLSource) {
      this.setState({
        source: URLSource,
        referrer: URLReferrer
      })
    }
  }
  

  async confirmUser () {
    // If user needs to confirm their registration, process the confirmation code
    const searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.get('code');
    if (code) {
      const username = searchParams.get('username');
      const password = searchParams.get('password');
      this.setState({ 
        userName: username,
        tempPassword: password 
      })
      try {
        // Confirm user email
        await Auth.confirmSignUp(username, code)
        mixpanel.track('User confirmed email for signup');
        toaster.success('Email confirmed successfully!');
        
        // Mark URL as done
        searchParams.set('status', 'done');
        const updatedSearchString = searchParams.toString();
        const newUrl = `${window.location.origin}${window.location.pathname}?${updatedSearchString}`;
        window.history.replaceState(null, '', newUrl);

        // Request setting new password
        this.setState({ showSetFirstPasswordShown: true })
        
      } catch (e) {
        // If code is expired, send a new one
        if (e.code === 'ExpiredCodeException') {
          toaster.warning('Your verification link has expired and we\'ve just emailed you a code that you\'ll need to enter to complete your registration.');
          this.setState({ 
            requestConfirmationCode: true,
            userName: username
          })
          await Auth.resendSignUp(username)
          this.setState({ showSetFirstPasswordShown: true })
        }
        // Surpress erroneous error messages
        if (e.code !== 'NotAuthorizedException' && e.code !== 'LimitExceededException') {
          toaster.warning('Error. Please contact support');
          Bugsnag.notify(e)
        }
      }
    }
  }

  async setFirstPassword () {
    const password = document.getElementById('firstPassword').value
    const confirmedPassword = document.getElementById('firstPasswordConfirmation').value
    var code = ""
    if (this.state.requestConfirmationCode) {
      code = document.getElementById('code').value
    }
    if (!password || !confirmedPassword || password !== confirmedPassword || (this.state.requestConfirmationCode && !code)) {
      toaster.warning('Please fill in all fields and ensure passwords match.')
      this.setState({ firstPasswordSetLoading: false })
      return
    }
    if (password.length < 8) {
      toaster.warning('Password must be at least 8 characters long.')
      this.setState({ firstPasswordSetLoading: false })
      return
    }
    try {

      if (this.state.requestConfirmationCode) {
        await Auth.confirmSignUp(this.state.userName, code)
      }
      // Sign in and setup new user
      await this.firstSignIn(this.state.userName, this.state.tempPassword, password)
    }
    catch (e) {
      toaster.warning('Error. Please contact support');
      Bugsnag.notify(e)
    }
  }

  async resetPassword() {
    const searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.get('code');
    const username = searchParams.get('username');
    const password = document.getElementById('resetPassword').value
    if (password.length < 8) {
      toaster.warning('Password must be at least 8 characters long.')
      this.setState({passwordResetLoading: false})
      return
    }
    try {
      await Auth.forgotPasswordSubmit(username, code, password)
      mixpanel.track('User reset password');
      toaster.success('Password reset successfully, please sign in with your email and the new password you provided.');
      // Mark URL as done
      searchParams.set('status', 'done');
      const updatedSearchString = searchParams.toString();
      const newUrl = `${window.location.origin}${window.location.pathname}?${updatedSearchString}`;
      window.history.replaceState(null, '', newUrl);
    } catch (e) {
      // Surpress erroneous error messages
      if (e.code !== 'NotAuthorizedException' && e.code !== 'LimitExceededException') {
        Bugsnag.notify(e)
        toaster.warning('Your password reset link has expired. Please request a new one and try again.');
      }
    }
    this.setState({
      passwordResetModal: false,
      passwordResetLoading: false,
    })
  }

  async forgotPassword () {
    const resetPasswordEmail = document.getElementById('resetPasswordEmail').value
    if (!resetPasswordEmail) {
      toaster.warning('Please enter a valid email.', {duration: 5,})
      this.setState({forgotPasswordLoading: false})
      return
    }
    const username = document.getElementById('resetPasswordEmail').value
    try {
      await Auth.forgotPassword(username)
      mixpanel.track('User requested password reset');
    } catch (e) {
      if (e.code !== 'UserNotFoundException') {
        Bugsnag.notify(e)
      }
    }
    toaster.success('If your email associated with a WishWell account, a link to reset your password has just been sent to your email. Make sure to check your spam folder.')
    this.setState({
      forgotPasswordModal: false,
      forgotPasswordLoading: false
    })
  }

  async signUp() {
    const username = document.getElementById('registerUsername').value.toLowerCase();
    const orgType = this.state.orgType
    const orgName = document.getElementById('orgName').value
    const orgCountry = this.state.country
    mixpanel.alias(username)
    mixpanel.identify(username)
    mixpanel.people.set({
      '$email': username,
      'User ID': username,
    })
    Bugsnag.addMetadata('Variables', {
      'Username': username
    })
    let email = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!email.test(username)) {
      toaster.warning("Please enter in a valid email.")
      this.setState({registrationLoading: false})
      return
    }
    if (!orgType) {
      toaster.warning("Please select an organization type.")
      this.setState({registrationLoading: false})
      return
    }
    if (!orgCountry) {
      toaster.warning("Please select a country.")
      this.setState({registrationLoading: false})
      return
    }
    if (!orgName) {
      toaster.warning("Please provide an organization name.")
      this.setState({registrationLoading: false})
      return
    }
    if (!this.state.legalStuff) {
      toaster.warning("Please accept the terms.")
      this.setState({registrationLoading: false})
      return
    }
    try {
      const tempPassword = this.generatePassword(8);
      await Auth.signUp({
          username,
          password: tempPassword,
          attributes: {
            email: username,
            'custom:orgType': orgType,
            'custom:orgName': orgName,
            'custom:userType': 'founder',
            'custom:initialPassword': tempPassword,
            'custom:source': this.state.source || 'Direct',
            'custom:referrer': this.state.referrer || 'None',
            'custom:country': orgCountry
          }
      })

      this.subscribeToHubspot(username, orgName, orgType)
      toaster.success('Welcome to WishWell! Please check your email to verify your account, so you can get started.');
      mixpanel.track('Customer signed up!');
      mixpanel.people.set_once({
        'Sign-up Date': new Date().toISOString(),
      });
      mixpanel.people.set({
        'Lifecycle Stage': 'Customer',
      });
      this.setState({
        registerModal: false,
        registration: 'none'
      })
    } catch (error) {
      console.log(error)
      this.setState({
        registerModal: false
      })
      if (error.code === 'UsernameExistsException') {
        toaster.warning('This email is already in use for another WishWell account. Please use a different email to register your organization.');
      }
      else {
        toaster.danger('Error. Please contact support.');
        Bugsnag.notify(error)
        Bugsnag.clearMetadata('Variables')
      }
    }
  }

  subscribeToHubspot(email, company, sector) {

    // Check if the email address ends with "@onegift.ai"
    if (email.endsWith('@onegift.ai')) {
      console.log(`Email ${email} not added to HubSpot because it ends with "@onegift.ai" and this is a demo.`);
      return;
    }

    const portalId = '24356242';
    const formGuid = '364f568d-2e6c-43bf-939d-f881df020748';

    const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`;

    const data = {
      submittedAt: new Date().getTime(),
      fields: [
        {
          name: 'email',
          value: email
        },
        {
          name: 'company', // based on hubspot form field
          value: company
        },
        {
          name: 'industry',
          value: sector
        },
      ]
    };

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(data => {
      console.log('Submission successful', data);
      // Do something with response data
    })
    .catch(error => {
      console.error('There was an error!', error);
      Bugsnag.notify(error)
    });
  }

  async firstSignIn(userName, pass, newPass) {
    try {
      
      await Auth.signIn(userName, pass)
        .then(async () => {
        const currentUser = await Auth.currentAuthenticatedUser();
        await Auth.changePassword(currentUser, this.state.tempPassword, newPass);
        toaster.success('Password updated successfully. You can now login to your account using your email and the password you just set.');
        this.setState({ showSetFirstPasswordShown: false })

        mixpanel.identify(userName)

        // Set user to Admin group
        const apiName = 'wishwellapi';
        var path = '/wishwellapi/addUserToAdminGroup';
        var myInit = { // OPTIONAL
            body: {
              userName: userName
            },
            headers: {}, // OPTIONAL
        };
        await API.post(apiName, path, myInit)
        await Auth.signOut();
      })
        .catch (error => {
          // toaster.warning('An error has occurred setting up your account. Please contact customer support.', {duration: 15,})
          Bugsnag.notify(error);
          console.error(error)
        })
    } catch (error) {
      // toaster.warning('An error has occurred setting up your account. Please contact customer support.', {duration: 15,})
      Bugsnag.notify(error);
      console.error(error)
    }
  }

  async setupNewOrganization(userName, user_info) {
    try {
      const userType = user_info.attributes['custom:userType']
      // New user signin
      if (userType === 'userCreated') {
        mixpanel.track("New user signed in")
      }

      // New founder user signin
      else {
        mixpanel.track("New customer signed in")

        // Create org
        const orgId = uuid()
        const orgName = user_info.attributes['custom:orgName']
        const orgType = user_info.attributes['custom:orgType']
        const orgCountry = user_info.attributes['custom:country']
        const source = user_info.attributes['custom:source']
        const referrer = user_info.attributes['custom:referrer']
        const newOrg = {
          id: orgId,
          name: orgName,
          active: true,
          // Sets the parent org of all new orgs to WishWell
          parentOrgId: '62e89a97-81fd-48b8-b78f-4183170e6274',
          independent: true,
          source: source,
          referrer: referrer,
        }
        const orgPromise = await API.graphql(graphqlOperation(createOrganization, { input: newOrg }))

        // Create user
        const newUser = {
          id: uuid(),
          userName: userName,
          NPS: '{date=' + new Date().toISOString().split('T')[0] + ', score=none}',
          permissions: ['{value=Super Admin, label=Super Admin}'],
          lifeCycle: 'Customer',
          organizationId: orgId,
        }
        const userPromise = await API.graphql(graphqlOperation(createUser, { input: newUser }))
        
        // Create org settings

        // Ensure makeawishurl is unique
        var makeAWishURL = orgName.replace(/ /g, "-").toLowerCase().substring(0, 21);
        var tempMakeAWishURL = makeAWishURL
        var counter = 0
        var mawSettings = await API.graphql(graphqlOperation(settingsByMakeAWishURL, { makeAWishURL: tempMakeAWishURL }))
        while (mawSettings.data.settingsByMakeAWishURL.items.length > 0) {
          tempMakeAWishURL = makeAWishURL + counter
          mawSettings = await API.graphql(graphqlOperation(settingsByMakeAWishURL, { makeAWishURL: tempMakeAWishURL }))
          counter++
        }
        makeAWishURL = tempMakeAWishURL

        // Create Stripe Connect Express account
        const countryInfo = this.state.stripeCountryInfo.find(country => country.country === orgCountry);
        const apiName = 'wishwellpayments';
        var path = '/payments/createExpressAccount';
        var myInit = { // OPTIONAL
            body: {
              countryCode: countryInfo.code,
              currency: countryInfo.currency,
              email: userName,
            },
            headers: {}, // OPTIONAL
        };
        const stripe = await API.post(apiName, path, myInit);
        const stripeAccountId = stripe.body.id

        const newSettings = {
          id: uuid(),
          chapterName: orgName,
          orgEmail: userName,
          minWishPrice: 1,
          events: ['{value=Birthdays, label=Birthdays}'],
          customFields: [],
          makeAWishURL: makeAWishURL,
          defaultWishers: [],
          envelopeReturnName: orgName,
          envelopeReturnAddressLine1: "",
          envelopeReturnAddressLine2: "",
          logo: ["",""],
          brandColor: "#0A003A",
          taxNumber: "",
          orgType: '{value=' + orgType + ', label=' + orgType + '}',
          giveLocal: false,
          paymentType: "",
          paymentId: "",
          country: '{value=' + orgCountry + ', label=' + orgCountry + '}',
          currency: countryInfo.currency,
          bankInfo: ["","",""],
          stripeAccountId: stripeAccountId,
          stripeOnboardingComplete: false,
          birthdayBooster: true,
          referrer: "",
          referralCredits: 0,
          organizationId: orgId,
        }
        const orgSettingsPromise = await API.graphql(graphqlOperation(createSetting, { input: newSettings }))

        Promise.all([orgPromise, userPromise, orgSettingsPromise])
          .then(() => {
            // Track sign-in event
            mixpanel.track('Signed-in');
            Bugsnag.setUser(userName)
      
            // this.props.onStateChange('signedIn', {})
          })
          .catch((error) => {
            console.error("Error creating org, user, or org settings: ", error);
          });
      }
      // Track sign-in event
      mixpanel.track('Signed-in');
      Bugsnag.setUser(userName)
    } catch (error) {
      // toaster.warning('An error has occurred setting up your account. Please contact customer support.', {duration: 15,})
      Bugsnag.notify(error);
      console.error(error)
    }
  }

  async signIn() {
    const username = document.getElementById('username').value.toLowerCase();
    const password = document.getElementById('password').value

    if ((!username || !password)) {
      toaster.warning('Please enter a valid username and password.', {duration: 5,})
      this.setState({ signInLoading: false })
      return
    }
  
    this.setState({ signInLoading: true })
  
    Auth.signIn(username, password)
      .then(async () => {
      
        // check if user has been added to db and set them up if not
        const user = await API.graphql(graphqlOperation(userByUserName, { userName: username }))
        if (!user || !user.data || !user.data.userByUserName || !user.data.userByUserName.items || !user.data.userByUserName.items[0]) {
          const user_info = await Auth.currentUserInfo()
          await this.setupNewOrganization(username, user_info)
          // Track sign-in event
          mixpanel.track('New user Signed-in');
          Bugsnag.setUser(username)
    
          this.props.onStateChange('signedIn', {})
        }
        else {
          // Track sign-in event
          mixpanel.track('New user Signed-in');
          Bugsnag.setUser(username)
    
          this.props.onStateChange('signedIn', {})
        }
      })
      .catch(err => {
        mixpanel.track('Login failed: ' + err.code);
        if (err.code === 'UserNotConfirmedException') {
          this.props.updateUsername(username)
          this.props.onStateChange('confirmSignUp', {})
          toaster.warning('Please confirm your email by clicking the link that was sent to you before logging in. Make sure to check your spam folder and contact support if you\'re still having issues.', {duration: 5,})
        } else if (err.code === 'NotAuthorizedException') {
          // The error happens when the incorrect password is provided
          this.setState({error: 'Login failed.'})
          toaster.warning('The username and/or password you entered is invalid. Please try again or contact support.', {duration: 5,})
        } else if (err.code === 'UserNotFoundException') {
          // The error happens when the supplied username/email does not exist in the Cognito user pool
          this.setState({error: 'Login failed.'})
          toaster.warning('The username and/or password you entered is invalid. Please try again or contact support.', {duration: 5,})
        } else {
          Bugsnag.notify(err);
          this.setState({error: 'An error has occurred with your signin. Please try again.'})
          console.error(err)
        }
      })
      .finally(() => {
        this.setState({ signInLoading: false })
    });
  }

  handleInputChange(evt) {
    this.inputs = this.inputs || {}
    const {name, value, type, checked} = evt.target
    const check_type = ['radio', 'checkbox'].includes(type)
    this.inputs[name] = check_type ? checked : value
    this.inputs['checkedValue'] = check_type ? value : null
    this.setState({error: ''})
  }

  generatePassword(length) {
    const chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let password = "";
    for (let i = 0; i < length; i++) {
      password += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return password;
  }

  render() {
    if (this.props.authState !== 'signedIn') {
        return (
          <div>
            {this.navBar()}
            <div className='signin-container'>
            {this._validAuthStates.includes(this.props.authState) && (
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center", backgroundColor: "white", height: "100vh" }}>
              <div className="login-form">
              <h3 style={{ fontFamily: 'Verdana' }}>{!this.state.counting ? this.state.wishes.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 }) : this.state.counter.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 })} wishes and counting!</h3>
              <img src={logo} alt='wishwellbyonegiftlogo' style={{ float: "center", height: "100px", padding: "30px" }}></img>
              <div>
                  <form onSubmit={(event) => {
                    event.preventDefault();
                    this.signIn();
                  }}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                    <TextInputField
                    id="username"
                    key="username"
                    name="username"
                    label="Email"
                    width={300}
                    onChange={this.handleInputChange}
                    disabled={this.state.signInLoading}
                    type="text"
                    />
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                    <TextInputField
                    id="password"
                    key="password"
                    name="password"
                    label="Password"
                    width={300}
                    onChange={this.handleInputChange}
                    disabled={this.state.signInLoading}
                    type="password"
                    />
                </div>
                    <button
                      style={{
                        cursor: 'pointer',
                        backgroundColor: '#b6eff1',
                        borderRadius: '2px',
                        padding: '10px 20px'
                      }}                      
                      disabled={this.state.signInLoading}
                    >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {this.state.signInLoading && <Spinner size={16} marginRight={8} />}
                      Login
                    </div>
                    </button>
                  </form>
                </div>
                {/* <p><a href="#" onclick="forgotPassword()" style={{textDecoration: 'underline'}}>I forgot my password</a></p> */}
                {/* <p><button onClick={() => this.setState({registerModal: true})} style={{border: 'none', backgroundColor: 'inherit', cursor: 'pointer', textDecoration: 'underline'}}>Create an account</button></p> */}
                <p><button id="create-account-button" className="hs-cta-trigger-button hs-cta-trigger-button-174923635599" style={{border: 'none', backgroundColor: 'inherit', cursor: 'pointer', textDecoration: 'underline'}}>Create an account</button></p>
                <p><button onClick={() => this.setState({forgotPasswordModal: true})} style={{border: 'none', backgroundColor: 'inherit', cursor: 'pointer', textDecoration: 'underline'}}>I forgot my password</button></p>
                {/* <p><button onClick={() => window.open('http://wishwell.ai/setuptutorial', '_blank')} style={{border: 'none', backgroundColor: 'inherit', cursor: 'pointer', textDecoration: 'underline'}}>15 minute setup, 10%+ donations growth, fully automated - see how</button></p> */}
                </div>
                <div>
              </div>
              </div>
              )}
            </div>
            <div class="video-container">
              <div style={{textAlign: "center"}}><a href="http://www.wishwell.ai/papcase" rel="noopener noreferrer" target="_blank" style={{display: "block", textAlign: "left;"}}><img src="https://onegift.ai/hubfs/PAP%20Case%20Thumbnail%20flat.png" style={{width: "100%", maxWidth: "600px", margin: "0 auto", borderRadius: "10px"}} alt="PAP Case Thumbnail"></img></a></div>
              <br></br>
              <iframe title="How WishWell Works" width="560" height="315" src="https://www.youtube.com/embed/aPLFG5Z3_hY?si=opIML_JxdpNrDLCo" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen style={{borderRadius: "10px", maxWidth: '90%'}}></iframe>
            </div>
            <footer style={{
                  display: "flex",
                  margin: "auto",
                  justifyContent: 'center',
                  alignItems: "center",
                  backgroundColor: '#0a003a',
                  position: "fixed",
                  bottom: "0px",
                  height: "50px",
                  width: "100%",
                  zIndex: 100, 
                  color: 'white'
                }}>
                  <p>WishWell was created by OneGift | <a href="https://onegift.ai/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'white' }}>Learn more</a> | <a href="mailto:experience@onegift.ai" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'white' }}>experience@onegift.ai</a>&nbsp; | +1 (951) 6ONEGIFT | <a href="https://onegift.ai/the-legal-stuff" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'white' }}>Legal Terms</a></p>
              </footer>
            {/* Dialog to register an account */}
            <div style={{ display: "flex", flexDirection: 'column', width: '300px', alignItems: "center", justifyContent: "center", textAlign: "center" }}>
              <Pane>
                <Dialog
                    isShown={this.state.registerModal}
                    shouldCloseOnOverlayClick={false}
                    shouldCloseOnEscapePress={false}
                    title="WishWell Registration"
                    isConfirmLoading={this.state.registrationLoading}
                    onConfirm={() => { this.setState({ registrationLoading: true }); this.signUp(); } }
                    confirmLabel={'Sign Up'}
                    onCloseComplete={() => this.setState({ registerModal: false, registrationLoading: false })}
                  >
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                      <TextInputField
                      id="orgName"
                      key="orgName"
                      name="orgName"
                      label="Organization Name"
                      width={300}
                      onChange={this.handleInputChange}
                      type="text"
                      />
                  </div>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                        <TextInputField
                        id="registerUsername"
                        key="registerUsername"
                        name="registerUsername"
                        label="Email"
                        width={300}
                        onChange={this.handleInputChange}
                        type="text"
                        />
                    </div>
                    <div style={{ display: "flex", flexDirection: 'column', margin: '0 auto', width: '300px', alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                      <Label htmlFor="orgType" marginBottom={4}>Organization Type</Label>
                      <Autocomplete
                          title="Organization Type"
                          id="orgType"
                          label="Organization Type"
                          onChange={changedItem => this.setState({ orgType: changedItem })}
                          items={['Nonprofit', 'Place of Worship', 'Place of Work', 'Community', 'School', 'Other']}
                          >
                          {props => {
                              const { getInputProps, getRef, inputValue, openMenu } = props
                              return (
                              <TextInputField
                                  placeholder="Organization Type"
                                  value={inputValue}
                                  ref={getRef}
                                  {...getInputProps({
                                    onFocus: () => {
                                      openMenu()
                                    }
                                  })}
                              />
                              )
                          }}
                      </Autocomplete>
                    </div>
                    <div style={{ display: "flex", flexDirection: 'column', margin: '0 auto', width: '300px', alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                      <Label htmlFor="orgCountry" marginBottom={4}>Organization Country</Label>
                      <Autocomplete
                          title="Organization Country"
                          id="orgCountry"
                          label="Organization Country"
                          onChange={changedItem => this.setState({ country: changedItem })}
                          items={this.state.countries ? this.state.countries.map((country) => country.value) : null}
                          >
                          {props => {
                              const { getInputProps, getRef, inputValue, openMenu } = props
                              return (
                              <TextInputField
                                  placeholder="Organization Country"
                                  value={inputValue}
                                  ref={getRef}
                                  {...getInputProps({
                                    onFocus: () => {
                                      openMenu()
                                    }
                                  })}
                              />
                              )
                          }}
                      </Autocomplete>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                        <Checkbox
                        // label={"I agree to the" + <a href="https://www.wishwellbyonegift.com/terms-of-service" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'black' }}>Terms of Service and Privacy Policy</a>}
                        label={
                          <span>I agree to the&nbsp;
                          <a href="https://onegift.ai/the-legal-stuff" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                            Terms
                          </a>
                          </span>
                        }
                        checked={this.state.legalStuff}
                        onChange={(e) => this.setState({ legalStuff: e.target.checked })}
                        width={300}
                        />
                    </div>
                </Dialog>
              </Pane>
            </div>
            {/* Dialog to set first password */}
            <div style={{ display: "flex", flexDirection: 'column', width: '300px', alignItems: "center", justifyContent: "center", textAlign: "center" }}>
              <Pane>
                <Dialog
                    isShown={this.state.showSetFirstPasswordShown}
                    shouldCloseOnOverlayClick={false}
                    shouldCloseOnEscapePress={false}
                    title="Set your password please"
                    isConfirmLoading={this.state.firstPasswordSetLoading}
                    onConfirm={() => { this.setState({ firstPasswordSetLoading: true }); this.setFirstPassword(); } }
                    confirmLabel={'Set New Password'}
                    onCloseComplete={() => this.setState({ showSetFirstPasswordShown: false, firstPasswordSetLoading: false })}
                  >
                    {this.state.requestConfirmationCode && <div>
                      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                        <TextInputField
                          id="code"
                          key="code"
                          name="code"
                          label="Code"
                          width={300}
                          onChange={this.handleInputChange}
                          type="text"
                        />
                    </div>
                    </div>}
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                        <TextInputField
                          id="firstPassword"
                          key="firstPassword"
                          name="firstPassword"
                          label="New Password"
                          width={300}
                          onChange={this.handleInputChange}
                          type="password"
                        />
                    </div>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                        <TextInputField
                          id="firstPasswordConfirmation"
                          key="firstPasswordConfirmation"
                          name="firstPasswordConfirmation"
                          label="Confirm Password"
                          width={300}
                          onChange={this.handleInputChange}
                          type="password"
                        />
                    </div>
                </Dialog>
              </Pane>
            </div>
            {/* Dialog to reset password */}
            <div style={{ display: "flex", flexDirection: 'column', width: '300px', alignItems: "center", justifyContent: "center", textAlign: "center" }}>
              <Pane>
                <Dialog
                    isShown={this.state.passwordResetModal}
                    shouldCloseOnOverlayClick={false}
                    shouldCloseOnEscapePress={false}
                    title="Password Reset"
                    isConfirmLoading={this.state.passwordResetLoading}
                    onConfirm={() => { this.setState({ passwordResetLoading: true }); this.resetPassword(); } }
                    confirmLabel={'Set New Password'}
                    onCloseComplete={() => this.setState({ passwordResetModal: false, passwordResetLoading: false })}
                  >
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                        <TextInputField
                        id="resetPassword"
                        key="resetPassword"
                        name="resetPassword"
                        label="New Password"
                        width={300}
                        onChange={this.handleInputChange}
                        type="password"
                        />
                    </div>
                </Dialog>
              </Pane>
            </div>
            {/* Dialog to collect email to reset password */}
            <div style={{ display: "flex", flexDirection: 'column', width: '300px', alignItems: "center", justifyContent: "center", textAlign: "center" }}>
              <Pane>
                <Dialog
                    isShown={this.state.forgotPasswordModal}
                    shouldCloseOnOverlayClick={false}
                    shouldCloseOnEscapePress={false}
                    title="Forgot Password"
                    isConfirmLoading={this.state.forgotPasswordLoading}
                    onConfirm={() => { this.setState({ forgotPasswordLoading: true }); this.forgotPassword(); } }
                    confirmLabel={'Reset Password'}
                    onCloseComplete={() => this.setState({ forgotPasswordModal: false, forgotPasswordLoading: false })}
                  >
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                        <TextInputField
                        id="resetPasswordEmail"
                        key="resetPasswordEmail"
                        name="resetPasswordEmail"
                        label="Your Email"
                        width={300}
                        onChange={this.handleInputChange}
                        type="text"
                        />
                    </div>
                </Dialog>
              </Pane>
            </div>
          </div>
        )
    }
    else {
        return null
    }
  }
  navBar() {
    return (
        <nav className='navbar'>
            <div style={{ position: "fixed", top: 0, left: 0, right: 0, zIndex: 999 }}>
                <div style={{ textAlign: "center", backgroundColor: '#0A003A', padding: "20px", height: "40px" }}>
                    <div><img src={wishWellLogo} alt={wishWellLogo} style={{ float: "left", height: "40px" }}></img></div>
                    <div className='nav-links'>
                    &nbsp;<Popover
                          position={Position.BOTTOM_LEFT}
                          content={
                            <Menu>
                              <Menu.Group>
                                <Menu.Item icon={PeopleIcon}>
                                  &nbsp;<Button appearance="minimal" onClick={() => window.open('https://onegift.ai/communities', '_blank')}>For Communities For Good</Button>
                                </Menu.Item>
                                <Menu.Item icon={HandIcon}>
                                  &nbsp;<Button appearance="minimal" onClick={() => window.open('https://onegift.ai/nonprofits', '_blank')}>For Nonprofits</Button>
                                </Menu.Item>
                                <Menu.Item icon={SymbolCrossIcon}>
                                  &nbsp;<Button appearance="minimal"  onClick={() => window.open('https://onegift.ai/churches', '_blank')}>For Churches</Button>
                                </Menu.Item>
                                <Menu.Item icon={FlashIcon}>
                                  &nbsp;<Button appearance="minimal"  onClick={() => window.open('https://onegift.ai/resources/faithbased', '_blank')}>For Other Faith-based Communities</Button>
                                </Menu.Item>
                                <Menu.Item icon={BookIcon}>
                                  &nbsp;<Button appearance="minimal"  onClick={() => window.open('https://onegift.ai/alumni', '_blank')}>For Alumni Groups</Button>
                                </Menu.Item>
                                <Menu.Item icon={HomeIcon}>
                                  &nbsp;<Button appearance="minimal"  onClick={() => window.open('https://onegift.ai/neighborhoods', '_blank')}>For Neighborhoods</Button>
                                </Menu.Item>
                                <Menu.Item icon={UserIcon}>
                                  &nbsp;<Button appearance="minimal"  onClick={() => window.open('https://onegift.ai/clubs', '_blank')}>For Clubs</Button>
                                </Menu.Item>
                                <Menu.Item icon={WalkIcon}>
                                  &nbsp;<Button appearance="minimal"  onClick={() => window.open('https://onegift.ai/retirementcommunities', '_blank')}>For Retirement Communities</Button>
                                </Menu.Item>
                                <Menu.Item icon={InheritedGroupIcon}>
                                  &nbsp;<Button appearance="minimal"  onClick={() => window.open('https://onegift.ai/friendsandfamily', '_blank')}>For Friends & Family</Button>
                                </Menu.Item>
                                <Menu.Item icon={DollarIcon}>
                                  &nbsp;<Button appearance="minimal"  onClick={() => window.open('https://onegift.ai/resources/forprofits', '_blank')}>For Companies</Button>
                                </Menu.Item>
                              </Menu.Group>
                            </Menu>
                          }
                        >
                          <Button iconBefore={CogIcon}>How It Works</Button>
                      </Popover>
                      &nbsp;<Button iconBefore={CleanIcon} onClick={() => window.open('https://www.wishwell.onegift.ai/makeawish', '_blank')}>Make Wishes</Button>
                      &nbsp;<Popover
                          position={Position.BOTTOM_LEFT}
                          content={
                            <Menu>
                              <Menu.Group>
                                <Menu.Item icon={VideoIcon}>
                                  &nbsp;<Button appearance="minimal" onClick={() => window.open('http://wishwell.ai/demo', '_blank')}>Watch Demo</Button>
                                </Menu.Item>
                                <Menu.Item icon={CalendarIcon}>
                                  &nbsp;<Button appearance="minimal"  onClick={() => window.open('http://wishwell.ai/scheduledemo', '_blank')}>Schedule Demo</Button>
                                </Menu.Item>
                              </Menu.Group>
                            </Menu>
                          }
                        >
                          <Button iconBefore={EyeOpenIcon}>Demo</Button>
                      </Popover>
                      &nbsp;<Button iconBefore={HelpIcon} onClick={() => window.open('https://www.onegift.ai/resources', '_blank')}>Help</Button>
                  </div>
                  <div class="mobile">
                        <Popover
                        position={Position.BOTTOM_LEFT}
                        content={
                          <Menu>
                            <Menu.Group>
                            <Menu.Item icon={CogIcon}>
                                &nbsp;<Popover
                                  position={Position.BOTTOM_LEFT}
                                  content={
                                    <Menu>
                                      <Menu.Group>
                                        <Menu.Item icon={PeopleIcon}>
                                          &nbsp;<Button appearance="minimal" onClick={() => window.open('https://onegift.ai/communities', '_blank')}>For Communities For Good</Button>
                                        </Menu.Item>
                                        <Menu.Item icon={HandIcon}>
                                          &nbsp;<Button appearance="minimal" onClick={() => window.open('https://onegift.ai/nonprofits', '_blank')}>For Nonprofits</Button>
                                        </Menu.Item>
                                        <Menu.Item icon={SymbolCrossIcon}>
                                          &nbsp;<Button appearance="minimal"  onClick={() => window.open('https://onegift.ai/churches', '_blank')}>For Churches</Button>
                                        </Menu.Item>
                                        <Menu.Item icon={FlashIcon}>
                                          &nbsp;<Button appearance="minimal"  onClick={() => window.open('https://onegift.ai/resources/faithbased', '_blank')}>For Other Faith-based Communities</Button>
                                        </Menu.Item>
                                        <Menu.Item icon={BookIcon}>
                                          &nbsp;<Button appearance="minimal"  onClick={() => window.open('https://onegift.ai/alumni', '_blank')}>For Alumni Groups</Button>
                                        </Menu.Item>
                                        <Menu.Item icon={HomeIcon}>
                                          &nbsp;<Button appearance="minimal"  onClick={() => window.open('https://onegift.ai/neighborhoods', '_blank')}>For Neighborhoods</Button>
                                        </Menu.Item>
                                        <Menu.Item icon={UserIcon}>
                                          &nbsp;<Button appearance="minimal"  onClick={() => window.open('https://onegift.ai/clubs', '_blank')}>For Clubs</Button>
                                        </Menu.Item>
                                        <Menu.Item icon={WalkIcon}>
                                          &nbsp;<Button appearance="minimal"  onClick={() => window.open('https://onegift.ai/retirementcommunities', '_blank')}>For Retirement Communities</Button>
                                        </Menu.Item>
                                        <Menu.Item icon={InheritedGroupIcon}>
                                          &nbsp;<Button appearance="minimal"  onClick={() => window.open('https://onegift.ai/friendsandfamily', '_blank')}>For Friends & Family</Button>
                                        </Menu.Item>
                                        <Menu.Item icon={DollarIcon}>
                                          &nbsp;<Button appearance="minimal"  onClick={() => window.open('https://onegift.ai/resources/forprofits', '_blank')}>For Companies</Button>
                                        </Menu.Item>
                                      </Menu.Group>
                                    </Menu>
                                  }
                                >
                                  <Button icon={CogIcon} appearance="minimal" marginRight={0} style={{ marginLeft: 'auto' }}>How It Works</Button>
                                </Popover>
                              </Menu.Item>
                              <Menu.Item icon={CleanIcon}>
                                  &nbsp;<Button appearance="minimal" onClick={() => window.open('https://www.wishwell.onegift.ai/makeawish', '_blank')}>Make Wishes</Button>
                              </Menu.Item>
                              <Menu.Item icon={LearningIcon}>
                                <Popover
                                  position={Position.BOTTOM_LEFT}
                                  content={
                                    <Menu>
                                      <Menu.Group>
                                        <Menu.Item icon={VideoIcon}>
                                          &nbsp;<Button appearance="minimal" onClick={() => window.open('http://wishwell.ai/demo', '_blank')}>Watch Demo</Button>
                                        </Menu.Item>
                                        <Menu.Item icon={CalendarIcon}>
                                          &nbsp;<Button appearance="minimal"  onClick={() => window.open('http://wishwell.ai/scheduledemo', '_blank')}>Schedule Demo</Button>
                                        </Menu.Item>
                                      </Menu.Group>
                                    </Menu>
                                  }
                                >
                                  <Button icon={LearningIcon} appearance="minimal" marginRight={0} style={{ marginLeft: 'auto' }}>Demo</Button>
                                </Popover>
                              </Menu.Item>
                              <Menu.Item icon={HelpIcon}>
                                &nbsp;<Button appearance="minimal" onClick={() => window.open('https://www.onegift.ai/resources', '_blank')}>Help</Button>
                              </Menu.Item>
                            </Menu.Group>
                          </Menu>
                        }
                      >
                        <IconButton icon={AlignJustifyIcon} marginRight={0} style={{ marginLeft: 'auto' }} />
                      </Popover>
                    </div>
                </div>
            </div>
        </nav>
    )
}
}